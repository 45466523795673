import {Injectable} from '@angular/core';
import {RequestMethod, Response} from '@angular/http';
import {RequestConfig} from 'app/shared/rest/rest.request';
import {CustomHttp} from 'app/common/http/custom.http';
import {Observable} from 'rxjs/Observable';
import {ImageBase64Request} from "../../common/http/imageBase64.request";
import {DocumentBase64Request} from "../../common/http/documentBase64.request";
import {ServicesSubject} from "../../common/http/services.subject";
import {PageImpl} from "../../common/http/PageImpl";
import {
    CanalConsulta,
    ConsultaView, ConsultTypes,
    EstadoConsulta,
    EstatusConsulta,
    ReferenciaView,
} from './consulta.classes';
import {PageRequest} from "../../common/http/page.request";
import {DocumentoView, EstudioLaboratorioView, ImagenLaboratorioView} from "../../shared/classes/common.classes";
import {NiomedicPageRequest} from "../../common/http/niomedic.page.request";
import {CatEstadoConsultaView} from '../../shared/services/catalogos.service';
import {environment} from "../../../environments/environment";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {_throw} from 'rxjs/observable/throw';
import { Cie10 } from 'app/shared/classes/cies.classes';

@Injectable()
export class ConsultaService {
    // region Configuraciones de la solicitud, URL, Parametros, Tipo Verbo HTTP
    private GET_CONSULTA = new RequestConfig('Consulta-Get', 'application/json', true, RequestMethod.Get, '/consulta/{idConsulta}');
    private GET_LAST_CONSULTA = new RequestConfig('Consulta-GetLast', 'application/json', true, RequestMethod.Get, '/consulta/ultima/{idPaciente}?idGroup={idGroup}');
    private GET_CONSULTA_PAGE = new RequestConfig('Consulta-GetPage', 'application/json', true, RequestMethod.Get, '/consulta/page');
    private GET_CONSULTA_SEARCH = new RequestConfig('Consulta-GetPage', 'application/json', true, RequestMethod.Get, '/consulta/search');
    private POST_CONSULTA = new RequestConfig('Consulta-Post', 'application/json', true, RequestMethod.Post, '/consulta/');  // Actualizada con estatus consulta
    private POST_REF = new RequestConfig('REFERENCIA-Post', 'application/json', true, RequestMethod.Post, '/referencia/'); 

    
    private PUT_CONSULTA = new RequestConfig('Consulta-Put', 'application/json', true, RequestMethod.Put, '/consulta/');
    private PUT_CONSULTA_END = new RequestConfig('consulta-End', 'application/json', true, RequestMethod.Put, '/consulta/terminar/{idConsulta}');
    private PUT_CONSULTA_START = new RequestConfig('Consulta-Start ', 'application/json', true, RequestMethod.Put, '/consulta/iniciar/{idConsulta}');
    private PUT_CONSULTA_CANCEL = new RequestConfig('consulta-Cancel', 'application/json', true, RequestMethod.Put, '/consulta/cancelar/{idConsulta}'); // Actualizada con estatus consulta
    private PUT_CONSULTA_ENFERMERIA = new RequestConfig('consulta-Cancel', 'application/json', true, RequestMethod.Put, '/consulta/enfermeria/{idConsulta}'); // Actualizada con estatus consulta
    private GET_CONSULA_LABSTUDIES = new RequestConfig('Consulta-GetLabStudies', 'application/json', true, RequestMethod.Get, '/estudio-laboratorio/page');

    private GET_IMAGE_PAGE = new RequestConfig("GETIMGPAGE", "application/json", true, RequestMethod.Get, "/imagen-laboratorio/page");
    private GET_IMAGE_EXTERNALVIEW = new RequestConfig("Consulta-GetImageExternalViewer", "text/plain", true, RequestMethod.Get, "/consulta/{idConsulta}/urlImagen");
    private POST_IMAGE = new RequestConfig("POSTIMG", "multipart/form-data", true, RequestMethod.Post, "/imagen-laboratorio/subir/{idPaciente}");
    private POST_IMAGE_B64 = new RequestConfig("POSTIMGB64", "application/json", true, RequestMethod.Post, "/imagen-laboratorio/subir-archivo/{idPaciente}");
    private GET_DOCUMENT_PAGE = new RequestConfig("GETDOCPAGE", "application/json", true, RequestMethod.Get, "/documentos/page");
    private POST_DOCUMENT_B64 = new RequestConfig("POSTDOCB64", "application/json", true, RequestMethod.Post, "/documentos/subir-documento/{idPaciente}");

    private GET_ESTATUS_CONSULTAS = new RequestConfig('Consulta-GetEstatusConsultas', 'application/json', true, RequestMethod.Get, '/cat-estatus-consulta');
    private GET_CANAL_CONSULTAS = new RequestConfig('Consulta-GetTipoConsultas', 'application/json', true, RequestMethod.Get, '/cat-tipo-consulta');
    private rcfGetCatEstadoConsulta = new RequestConfig('GetCatEstadoConsulta', 'application/json', true, RequestMethod.Get, '/cat-estado-consulta');
    private GET_CONSULT_STATE_BY_ID = new RequestConfig('consultation-statE-by-id', 'application/json', true, RequestMethod.Get, '/catalogo-estado-consulta/{idEstadoConsulta}');
    // endregion

    // consultas dasboard

    private GET_CONSULTAFS = new RequestConfig('Consulta-Get', 'application/json', true, RequestMethod.Get, '/consulta/findAll?idGroup={IdGroup}');
    private GET_CONSULTAFD= new RequestConfig('Consulta-Get', 'application/json', true, RequestMethod.Get, '/consulta/findAll?idGroup={IdGroup}&idMedico={IdMedico}');
    private GET_CONSULTArefinter= new RequestConfig('Consulta-Get', 'application/json', true, RequestMethod.Get, '/referencia?searchColumn={searchColumn}&type={type}&value={value}');
    private GET_CONSULTACrefinter= new RequestConfig('Consulta-Get', 'application/json', true, RequestMethod.Get, '/contra-referencia?searchColumn={searchColumn}&value={value}');
    
    private GET_ALL_CIE10 = new RequestConfig("GETALLCIE10", "application/json", true, RequestMethod.Get, "/cat-cie10/findAll");
    private GET_PAGE_CIE10 = new RequestConfig("GETPAGECIE10", "application/json", true, RequestMethod.Get, "/cat-cie10/search");
    private GET_CIE10 = new RequestConfig("GETCIE9", "application/json", true, RequestMethod.Get, "/cat-cie10/{idCie10}");


    // region Observables
    // create observable to update consultation info on every component
    private consulta: ServicesSubject<ConsultaView> = new ServicesSubject();
    private tiposConsulta: ServicesSubject<CanalConsulta[]> = new ServicesSubject();
    private estatusConsulta: ServicesSubject<EstatusConsulta[]> = new ServicesSubject();
    private mapEstatusConsulta: ServicesSubject<Map<string, number>> = new ServicesSubject();
    private mapTiposConsulta: ServicesSubject<Map<string, number>> = new ServicesSubject();
    private estadoConsulta: ServicesSubject<CatEstadoConsultaView> = new ServicesSubject();

    lastFilterConsulta: NiomedicPageRequest;
    // endregion

    tabsSOAP: string[] = [
        'SUBJETIVO',
        'OBJETIVO',
        'ANALISIS',
        'PLAN TERAPEUTICO Y TRATAMIENTO'
    ];
    activeTabSOAP: number = 0;
    insideMenu: boolean = false;
    agregarPadecimientoDefault: boolean = false;

    constructor(private http: CustomHttp,
        private htppClient: HttpClient) {
    }


    getfindallconsultasS(IdGroup : number,pageRequest: PageRequest): Observable<any> {
        return this.http.sendRequest(this.GET_CONSULTAFS, pageRequest,IdGroup)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }
    getfindallconsultasM(IdMedico: string,IdGroup:number,pageRequest: PageRequest): Observable<any> {
        return this.http.sendRequest(this.GET_CONSULTAFD, pageRequest,IdGroup, IdMedico)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error)); 
    }

     // En searchColumn se pone número de la columna a buscar.
    // 1 - idPaciente
    // 2 - regionSanitaria
    // 3 - unidadMedica
    // 4 - idMedicoRecibe
    // 5 - idMedicoCrea


    // En type va número del tipo a buscar, estos son los que están en el modelo y por default está el 1.
    // TYPE_REFERENCIA = 1;
    // TYPE_INTERCONSULTA = 2;

    getfindallRefer(searchColumn:number,type:number,value: string,pageRequest: PageRequest): Observable<any> {
        return this.http.sendRequest(this.GET_CONSULTArefinter, pageRequest,searchColumn, type,value)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error)); 
    }


//     searchColumn va número de columna a buscar.
// 1 - idMedicoCref
// 2 - unidadMedica
// 3 - regionSanitaria

// Y en value va el valor que debas mandar, en cadena

    getfindallCRefer(searchColumn:number,value: string,pageRequest: PageRequest): Observable<any> {
        return this.http.sendRequest(this.GET_CONSULTACrefinter, pageRequest,searchColumn, value)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error)); 
    }

    /**
     * Obtener los detalles de consulta por su numero de consulta
     * @param {number} idConsulta
     * @param {boolean} refresh (optional)
     * @returns {Observable<ConsultaView>}
     */
    get(idConsulta: number, refresh?: boolean): Observable<ConsultaView> {
        if (this.consulta.needRefresh || refresh) {
            this.consulta.needRefresh = false;
            this.http.sendRequest(this.GET_CONSULTA, null, idConsulta)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.consulta.needRefresh = !this.consulta.data;
                    return this.http.handleError(error);
                })
                .subscribe(consultaView => {
                    this.consulta.subject.next(consultaView);
                    this.consulta.data = consultaView;
                });
        } else if (this.consulta.data) {
            if (this.consulta.data.idConsulta != idConsulta) {
                delete this.consulta.data;
                this.consulta.subject.next(new ConsultaView());
                this.get(idConsulta, true);
            }
        }

        return this.consulta.subject.asObservable();
    }
    onlyTheLastConsultation() {
        this.consulta.subject.complete();
    }

    repeatGet(idConsulta: number): boolean {
        if (this.consulta.data) {
            if (this.consulta.data.idConsulta == idConsulta) {
                this.consulta.subject.next(this.consulta.data);
                return true;
            }
        }

        return false;
    }


    
    getAllCie10(): Observable<Array<Cie10>> {
        return this.http.sendRequest(this.GET_ALL_CIE10)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    getPageCie10(pageRequest: PageRequest): Observable<PageImpl<Cie10>> {
        return this.http.sendRequest(this.GET_PAGE_CIE10, pageRequest)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    getCie10(idCie10: number): Observable<Cie10> {
        return this.http.sendRequest(this.GET_CIE10, {}, idCie10)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    getLastByPatient(idPaciente: string,idGroup : number): Observable<ConsultaView> {
        return this.http.sendRequest(this.GET_LAST_CONSULTA, null, idPaciente,idGroup)
            .map((res: Response) => {
                try {
                    return res.json();
                } catch (e) {
                    return null;
                }
            })
            .catch((error) => this.http.handleError(error));
    }

    /** 
     * Obtener listado de consultas por página
     * @params options NiomedicPageRequest
     *          {
     *              name?: string;
     *              page: number | ;
     *              size: number;
     *              orderColumn: string;
     *              orderType: string;
     *              startDate: Date;
     *              endDate: Date;
     *          }
     */
    getPage(options?: NiomedicPageRequest): Observable<PageImpl<ConsultaView>> {
        let startD: Date, endD: Date;
        startD = new Date();
        startD.setDate(1);
        startD.setHours(0, 0, 0, 0);
        endD = new Date();
        endD.setMonth(endD.getMonth() + 1, 0);
        endD.setHours(23, 59, 59, 99);

        let defOpt: NiomedicPageRequest = new NiomedicPageRequest;
        defOpt.orderColumn = 'idConsulta';
        defOpt.orderType = 'desc';
        defOpt.startDate = startD.getTime();
        defOpt.endDate = endD.getTime();

        defOpt = Object.assign(defOpt, options);

        return this.http.sendRequest(this.GET_CONSULTA_PAGE, defOpt)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    getPageSearch(options?: NiomedicPageRequest): Observable<PageImpl<ConsultaView>> {
        let startD: Date, endD: Date;
        startD = new Date();
        startD.setDate(1);
        startD.setHours(0, 0, 0, 0);
        endD = new Date();
        endD.setMonth(endD.getMonth() + 1, 0);
        endD.setHours(23, 59, 59, 99);

        let defOpt: NiomedicPageRequest = new NiomedicPageRequest;
        defOpt.orderColumn = 'fechaConsulta';
        defOpt.orderType = 'desc';
        defOpt.startDate = startD.getTime();
        defOpt.endDate = endD.getTime();

        defOpt = Object.assign(defOpt, options);

        return this.http.sendRequest(this.GET_CONSULTA_SEARCH, defOpt)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Obtener listado de consultas por página
     * @params options NiomedicPageRequest
     *          {
     *              name?: string;
     *              page: number | ;
     *              size: number;
     *              orderColumn: string;
     *              orderType: string;
     *              startDate: Date;
     *              endDate: Date;
     *          }
     */
    getSearch(options?: NiomedicPageRequest | any): Observable<PageImpl<ConsultaView>> {
        let startD: Date, endD: Date;
        startD = new Date();
        startD.setDate(1);
        startD.setHours(0, 0, 0, 0);
        endD = new Date();
        endD.setMonth(endD.getMonth() + 1, 0);
        endD.setHours(23, 59, 59, 99);

        let defOpt: NiomedicPageRequest = new NiomedicPageRequest;
        defOpt.orderColumn = 'fechaConsulta';
        defOpt.orderType = 'desc';
        defOpt.startDate = startD.getTime();
        defOpt.endDate = endD.getTime();

        defOpt = Object.assign(defOpt, options);

        return this.http.sendRequest(this.GET_CONSULTA_SEARCH, defOpt)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    save(consulta: ConsultaView): Observable<ConsultaView> {
        return this.http.sendRequest(this.POST_CONSULTA, consulta)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    saveREF(ref: ReferenciaView) {
        return this.http.sendRequest(this.POST_REF, ref)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Actualiza una consulta
     * @param {ConsultaView} consulta
     * @param {number} numeroConsulta
     */
    update(consulta: ConsultaView): Observable<Response> {
        return this.http.sendRequest(this.PUT_CONSULTA, consulta)
            .catch(error => this.http.handleError(error));
    }

    /**
     *      * {'id': 2,'descripcion': 'Consulta en Turno'},
     * {'id': 3,'descripcion': 'Cancelada'},
     * {'id': 4,'descripcion': 'Finalizada'}
     * @param consulta
     */
    finalizarConsulta(consulta: ConsultaView): Observable<Response> {
        consulta.feachaFin = new Date();
        consulta.estadoConsulta = 'FINALIZADA';
        consulta.idEstadoConsulta = 4;
        return this.http.sendRequest(this.PUT_CONSULTA_END, consulta, consulta.idConsulta)
            .catch(error => this.http.handleError(error));
    }

    iniciarConsulta(consulta: ConsultaView): Observable<Response> {
        return this.http.sendRequest(this.PUT_CONSULTA_START, {activo: true, color: '#00FF00', descripcion: 'CONSULTA EN TURNO', idEstadoConsulta: 2}, consulta.idConsulta)
            .catch(error => Observable.throw(error));
    }

    cancelarConsulta(consulta: ConsultaView, estadoConsultaActual: CatEstadoConsultaView): Observable<Response> {
        return this.http.sendRequest(this.PUT_CONSULTA_CANCEL, estadoConsultaActual, consulta.idConsulta)  // estadoConsultaActual, consulta.idConsulta
            /*.catch(/*error => this.http.handleError(error));*/
    }

    consultaEnfermeria(consulta: ConsultaView): Observable<Response> {
        let catEstadoConsulta = {
            "activo": true,
            "color": "",
            "descripcion": "EN ENFERMERIA",
            "idEstadoConsulta": 6
        };
        return this.http.sendRequest(this.PUT_CONSULTA_ENFERMERIA, catEstadoConsulta, consulta.idConsulta)  // estadoConsultaActual, consulta.idConsulta
            .catch(error => this.http.handleError(error));
    }

    /**
     * Obtener las imágenes de una consutla
     * @param consulta
     * @param options
     */
    getImagenesPage(consulta: ConsultaView, options?: NiomedicPageRequest): Observable<PageImpl<ImagenLaboratorioView>> {
        let defOpt: NiomedicPageRequest = new NiomedicPageRequest;
        defOpt.idPaciente = consulta.idPaciente;
        defOpt.idConsulta = consulta.idConsulta;
        defOpt.orderColumn = 'fechaCreacion';
        defOpt.orderType = 'asc';

        defOpt = Object.assign(defOpt, options);

        return this.http.sendRequest(this.GET_IMAGE_PAGE, defOpt)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    getImageExternalViewer(idConsulta: number): Observable<string> {
        return this.http.sendRequest(this.GET_IMAGE_EXTERNALVIEW, null, idConsulta)
            .map((res: Response) => res.text())
            .catch((error) => Observable.throw(error));
    }

    /**
     * Obtener los documentos por paciente
     * @param consulta ConsultaView
     * @param options NiomedicPageRequest
     */
    getDocumentosPage(consulta: ConsultaView, options?: NiomedicPageRequest): Observable<PageImpl<DocumentoView>> {
        let defOpt: NiomedicPageRequest = new NiomedicPageRequest;
        defOpt.idPaciente = consulta.idPaciente;
        defOpt.idConsulta = consulta.idConsulta;
        defOpt.orderColumn = 'fechaCreacion';
        defOpt.orderType = 'asc';

        defOpt = Object.assign(defOpt, options);

        return this.http.sendRequest(this.GET_DOCUMENT_PAGE, defOpt)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Hacer petición HTTP para obtener  los canales de consulta 1: Presencial, 2:Video Llamada
     * */
    getCanalConsulta(refresh?: boolean): Observable<CanalConsulta[]> {
        if (this.tiposConsulta.needRefresh || refresh) {
            this.tiposConsulta.needRefresh = false;
            this.http.sendRequest(this.GET_CANAL_CONSULTAS)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.tiposConsulta.needRefresh = !this.tiposConsulta.data;
                    return this.http.handleError(error)
                })
                .subscribe(catTipoConsulta => {
                    this.tiposConsulta.subject.next(catTipoConsulta);
                    this.tiposConsulta.data = catTipoConsulta;
                });
        }

        return this.tiposConsulta.subject.asObservable();
    }

    getEstadoConsultaActual(idEstadoConsulta: number, refresh?: boolean): Observable<CatEstadoConsultaView> {
        if (this.estadoConsulta.needRefresh || refresh) {
            this.estadoConsulta.needRefresh = false;
            this.http.sendRequest(this.GET_CONSULT_STATE_BY_ID, null, idEstadoConsulta)
                .map((res) => {
                    return res.json();
                })
                .catch(error => {
                    this.estadoConsulta.needRefresh = !this.estadoConsulta.data;
                    return this.http.handleError(error);
                })
                .subscribe(nextConsult => {
                    this.estadoConsulta.subject.next(nextConsult);
                    this.estadoConsulta.data = nextConsult;
                });
        } else if (this.estadoConsulta.data && this.estadoConsulta.data.idEstadoConsulta != idEstadoConsulta) {
            delete this.estadoConsulta.data;
            this.estadoConsulta.subject.next(new CatEstadoConsultaView());
            this.getEstadoConsultaActual(idEstadoConsulta, true);
        }
        return this.estadoConsulta.subject.asObservable();
    }

    /**
     * Hacer petición HTTP para obtener el estatus de la consulta
     * {'id': 1,'descripcion': 'No Iniciada'},
     * {'id': 2,'descripcion': 'Consulta en Turno'},
     * {'id': 3,'descripcion': 'Cancelada'},
     * {'id': 4,'descripcion': 'Finalizada'}
     */
    getEstatusConsulta(refresh?: boolean): Observable<EstatusConsulta[]> {
        if (this.estatusConsulta.needRefresh || refresh) {
            this.estatusConsulta.needRefresh = false;
            this.http.sendRequest(this.GET_ESTATUS_CONSULTAS)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.estatusConsulta.needRefresh = !this.estatusConsulta.data;
                    return this.http.handleError(error);
                })
                .subscribe(catEstatusConsulta => {
                    this.estatusConsulta.subject.next(catEstatusConsulta);
                    this.estatusConsulta.data = catEstatusConsulta;
                });
        }

        return this.estatusConsulta.subject.asObservable();
    }

    getMapEstatusConsulta(refresh?: boolean): Observable<Map<string, number>> {
        if (this.mapEstatusConsulta.needRefresh || refresh) {
            this.mapEstatusConsulta.needRefresh = false;
            this.getEstatusConsulta()
                .subscribe(catEstatusConsulta => {
                    this.mapEstatusConsulta.data = new Map();
                    catEstatusConsulta.forEach((catEstatus) => {
                        this.mapEstatusConsulta.data.set(
                            catEstatus.descripcion
                                .replace(/\b\w/g, wordFirstChar => wordFirstChar.toLocaleUpperCase())
                                .replace(/\s/g, ''),
                            catEstatus.id
                        );
                    });
                    this.mapEstatusConsulta.subject.next(this.mapEstatusConsulta.data);
                });
        }

        return this.mapEstatusConsulta.subject.asObservable();
    }

    getMapConsultTypes(refresh?: boolean): Observable<Map<string, number>> {
        if (this.mapTiposConsulta.needRefresh || refresh) {
            this.mapTiposConsulta.needRefresh = false;
            this.mapTiposConsulta.data = new Map();
            ConsultTypes.forEach((consultType) => {
                this.mapTiposConsulta.data.set(
                    consultType.descripcion,
                    consultType.id
                );
            });

            this.mapTiposConsulta.subject.next(this.mapTiposConsulta.data);
        }

        return this.mapTiposConsulta.subject.asObservable();
    }

    /**
     * Hacer petición HTTP para obtener el catalogo estado de la consulta
     * {'id': 1,'descripcion': 'Inicial'},
     * {'id': 2,'descripcion': 'Sub secuente'}
     */
    getEstadosConsulta(): Observable<EstadoConsulta[]> {
        return this.http.sendRequest(this.rcfGetCatEstadoConsulta)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    postDocumentB64(idPaciente: string, docB64: DocumentBase64Request) {
        return this.http.sendRequestFilePost(this.POST_DOCUMENT_B64, docB64, idPaciente)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Subir una imagen al repositorio del paciente, es necesario crear un FormData y ahi poner las imágenes, puede aceptar varias
     * los nombres de los archivos es 'file', cuando se hace un append al FormData
     * @see LlamadaPanelHrzComponent
     * @param form FormData
     * @param idPaciente string
     */
    postImage(form: FormData, idPaciente: string) {
        return this.http.sendRequestFilePosttoURL(environment.post_files, this.POST_IMAGE, form, idPaciente)
            .catch(error => this.http.handleError(error));
    }

    postImageB64(idPaciente: string, imgB64: ImageBase64Request) {
        return this.http.sendRequest(this.POST_IMAGE_B64, imgB64, idPaciente)
            .catch(error => this.http.handleError(error));
    }

    getEstudiesPage(opciones: PageRequest): Observable<PageImpl<EstudioLaboratorioView>> {
        return this.http.sendRequest(this.GET_CONSULA_LABSTUDIES, opciones)
            .map((res: Response) => res.json())
            .catch(error => Observable.throw(error)/*this.http.handleError(error)*/);
    }

    /**
     * Confirmar una consulta
     * @see RecepcionDashComponent
     * @param consulta
     * @param estadoConsulta
     */
    updateConfirmarConsulta(consulta: ConsultaView, estadoConsultaActual: CatEstadoConsultaView) {
        const CONFIRMAR_CONSULTA = new RequestConfig('updateConfirmarConsulta', 'application/json', true, RequestMethod.Put, '/consulta/confirmar/{idConsulta}');
        return this.http.sendRequest(CONFIRMAR_CONSULTA, estadoConsultaActual, consulta.idConsulta)
            .catch(error => this.http.handleError(error));
    }

    updateSignosVitalesConsulta(consulta: ConsultaView, catEstadoConsultaView: CatEstadoConsultaView, signosVitales: any) {
        // using object literals
        const data = {
            catEstadoConsultaView,
            signosVitales
        };
        const cnfg = new RequestConfig('updateSignosVitalesConsulta', 'application/json', true, RequestMethod.Put, '/consulta/{idConsulta}/signosVitales');
        return this.http.sendRequest(cnfg, data, consulta.idConsulta)
            .catch(error => this.http.handleError(error));
    }

    getEstadoConsulta(): Observable<CatEstadoConsultaView[]> {
        let cnfGeneral = new RequestConfig('GETCATALOGO', 'application/json', true, RequestMethod.Get, '/catalogo-estado-consulta/findAll');
        cnfGeneral.uri = '/catalogo-estado-consulta/findAll';
        cnfGeneral.code = 'getEstadoConsulta';
        cnfGeneral.method = RequestMethod.Get;
        return this.http.sendRequest(cnfGeneral)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }
}
