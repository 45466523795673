import {Injectable} from '@angular/core';
import {I18nService} from "../i18n";

declare var $: any;

@Injectable()
export class NotificationService {
    private _confirm1: string = this.i18n.getTranslation("Operation complete");
    private _aboutToDelete: string = this.i18n.getTranslation("You are about to delete");
    private _areYouSure: string = this.i18n.getTranslation("Are you sure?");
    private _activar: string = this.i18n.getTranslation("Activate?");
    private _confirmarActivar: string = this.i18n.getTranslation("You are about to activate the format");
    private _errorActivating: string = this.i18n.getTranslation("Error activating");
    private _desactivar: string = this.i18n.getTranslation("Deactivate?");
    private _confirmarDesactivar: string = this.i18n.getTranslation("You are about to deactivate the format");
    private _errorDeactivating: string = this.i18n.getTranslation("Error deactivating");
    private _delete: string = this.i18n.getTranslation("Delete");
    private _errorDeleting: string = this.i18n.getTranslation("Error deleting");

    constructor(private i18n: I18nService) {
        i18n.subscribe(() => {
            this._confirm1 = this.i18n.getTranslation("Operation complete");
            this._aboutToDelete = this.i18n.getTranslation("You are about to delete");
            this._areYouSure = this.i18n.getTranslation("Are you sure?");
            this._activar = this.i18n.getTranslation("Activate?");
            this._confirmarActivar = this.i18n.getTranslation("You are about to activate the format");
            this._errorActivating = this.i18n.getTranslation("Error activating");
            this._desactivar = this.i18n.getTranslation("Deactivate?");
            this._confirmarDesactivar = this.i18n.getTranslation("You are about to deactivate the format");
            this._errorDeactivating = this.i18n.getTranslation("Error deactivating");
            this._delete = this.i18n.getTranslation("Delete");
            this._errorDeleting = this.i18n.getTranslation("Error deleting");
        }, null)
    }

    smallBox(data, cb?) {
        $.smallBox(data, cb)
    }

    bigBox(data, cb?) {
        $.bigBox(data, cb)
    }

    smartMessageBox(data, cb?) {
        $.SmartMessageBox(data, cb)
    }

    smallBoxError(data, cb?) {
        const deftopt: IPropNotifData = {
            title: 'Error',
            content: 'Error',
            color: '#df295f',
            icon: 'fa fa-warning shake animated',
            timeout: 5000
        };
        this.smallBox(Object.assign({}, deftopt, data), cb);
    }

    smallBoxSuccess(data, cb?) {
        const deftopt: IPropNotifData = {
            title: 'Hecho!',
            content: 'Ok',
            color: '#739E73',
            icon: 'fa fa-check shake animated',
            timeout: 5000
        };
        this.smallBox(Object.assign({}, deftopt, data), cb);
    }

    bigBoxSuccess(data: IPropNotifData, cb?) {
        const deftopt: IPropNotifData = {
            title: 'Operación completada',
            content: 'Ok',
            color: '#739E73',
            icon: 'fa fa-check shake animated',
            timeout: 5000
        };

        this.bigBox(Object.assign({}, deftopt, data), cb);

    }

    bigBoxError(data: IPropNotifData, cb?) {
        const deftopt: IPropNotifData = {
            title: 'Error en la operación',
            content: 'Error',
            color: '#df295f',
            icon: 'fa fa-warning shake animated',
            timeout: 5000
        };

        this.bigBox(Object.assign({}, deftopt, data), cb);

    }

    get confirm1(): string {
        return this._confirm1;
    }

    get aboutToDelete(): string {
        return this._aboutToDelete;
    }

    get areYouSure(): string {
        return this._areYouSure;
    }

    get activar(): string {
        return this._activar;
    }

    get confirmarActivar(): string {
        return this._confirmarActivar;
    }

    get errorActivating(): string {
        return this._errorActivating;
    }

    get desactivar(): string {
        return this._desactivar;
    }

    get confirmarDesactivar(): string {
        return this._confirmarDesactivar;
    }

    get errorDeactivating(): string {
        return this._errorDeactivating;
    }

    get delete(): string {
        return this._delete;
    }

    get errorDeleting(): string {
        return this._errorDeleting;
    }

}

interface IPropNotifData {
    title?: string;
    content?: string;
    color?: string;
    icon?: string;
    timeout?: number;
    number?: number;
}
