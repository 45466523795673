import {Injectable} from "@angular/core";
import {RequestConfig} from "../rest/rest.request";
import {CustomHttp} from "../../common/http/custom.http";
import {Observable} from "rxjs/Observable";
import {RequestMethod} from "@angular/http";
import {PageImpl} from "../../common/http/PageImpl";
import {PageRequest} from "../../common/http/page.request";
import {Cie10, Cie9} from "../classes/cies.classes";

@Injectable()
export class CiesService {
    private GET_ALL_CIE9 = new RequestConfig("GETALLCIE9", "application/json", true, RequestMethod.Get, "/cat-cie9/findAll");
    private GET_PAGE_CIE9 = new RequestConfig("GETPAGECIE9", "application/json", true, RequestMethod.Get, "/cat-cie9/search");
    private GET_CIE9 = new RequestConfig("GETCIE9", "application/json", true, RequestMethod.Get, "/cat-cie9/{idCie9}");
    private GET_ALL_CIE10 = new RequestConfig("GETALLCIE10", "application/json", true, RequestMethod.Get, "/cat-cie10/findAll");
    private GET_PAGE_CIE10 = new RequestConfig("GETPAGECIE10", "application/json", true, RequestMethod.Get, "/cat-cie10/search");
    private GET_CIE10 = new RequestConfig("GETCIE9", "application/json", true, RequestMethod.Get, "/cat-cie10/{idCie10}");

    constructor(private http: CustomHttp) {
    }

    getAllCie9(): Observable<Array<Cie9>> {
        return this.http.sendRequest(this.GET_ALL_CIE9)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    getPageCie9(pageRequest: PageRequest): Observable<PageImpl<Cie9>> {
        return this.http.sendRequest(this.GET_PAGE_CIE9, pageRequest)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    getCie9(idCie9: number): Observable<Cie9> {
        return this.http.sendRequest(this.GET_CIE9, {}, idCie9)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    getAllCie10(): Observable<Array<Cie10>> {
        return this.http.sendRequest(this.GET_ALL_CIE10)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    getPageCie10(pageRequest: PageRequest): Observable<PageImpl<Cie10>> {
        return this.http.sendRequest(this.GET_PAGE_CIE10, pageRequest)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    getCie10(idCie10: number): Observable<Cie10> {
        return this.http.sendRequest(this.GET_CIE10, {}, idCie10)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }
}

export const DEFAULT_CIE10: number = 13650;