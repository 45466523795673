import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {PacienteService} from '../paciente.service';
import {PatientView} from '../paciente.classes';
import {Subscription} from "rxjs/Subscription";
import { CatalogosService } from 'app/shared/services/catalogos.service';
import {environment} from "environments/environment";


@Component({
    selector: 'app-paciente-ficha-layout',
    templateUrl: './paciente.ficha.layout.html',
    styleUrls: ['./paciente.ficha.layout.css']
})
export class PacienteFichaLayout implements OnInit, OnDestroy {
    @Input() paciente: PatientView | string;
    // En donde se requiera nowPaciente voy a emplear patient details.

    patientDetails: PatientView = new PatientView();
    patientAge: number;
    pacti :PatientView = new PatientView();
    tutor:boolean;
    subscriptions: Subscription = new Subscription();
    nombr:string;
    telef:string;
    email:string;
    paren:string;
    isAlentha:boolean=false;
    _idgroups : string ="";

    constructor(private catalogosService: CatalogosService,private pacienteService: PacienteService) {
    }

    ngOnInit() {
        // Patientview o IdPaciente
        console.log(this.paciente)
        if (this.paciente) {

            if (this.paciente instanceof PatientView) {
                    this.patientDetails = this.paciente;
                    this.cargarDatosPaciente();
            } else {
                    this.patientDetails.idPaciente = this.paciente;
                    this.cargarDatosPaciente();
            }

        }
        var seletedgroupd = localStorage.getItem('id_select_group');
        if(environment.Instance_file=='ECE'){
            this._idgroups='24';
        }else{
            this._idgroups='14';
        }
        if(seletedgroupd == this._idgroups){
            this.isAlentha=true;
        }

        console.log(this.patientDetails);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Cargar los datos del paciente del REST paciente, si ya tiene el nombre quiere decir que tiene los demás datos
     * (incluidos datos clínicos), ya no o pidas por HTTP
     */
    cargarDatosPaciente() {
        if (!this.patientDetails.nombre && this.patientDetails.idPaciente) {
            this.subscriptions.add(
                this.pacienteService.getById(this.patientDetails.idPaciente)
                    .subscribe(patientView => {
                        console.log(patientView);
                            this.tutor = patientView.es_titular;
                        console.log("dentro de cargar datos paciente");
                        console.log(this.tutor);
                            
                        if (!patientView.idPaciente) return;

                        if (patientView.idPaciente == this.patientDetails.idPaciente) {
                            this.patientDetails = patientView;
                            // if (this.patientDetails.fechaNacimiento.valueOf() < 0) { // less than 1970
                            //     let newFechaNacimiento: string = this.patientDetails.fechaNacimiento.valueOf().toString();
                            //     while (newFechaNacimiento.length < 13) { // 13 for milliseconds (with negative symbol -)
                            //         newFechaNacimiento += "0";
                            //     }
                            //     this.patientDetails.fechaNacimiento = new Date(parseInt(newFechaNacimiento));
                            // }
                            this.patientAge = Math.abs(new Date(Date.now() - new Date(this.patientDetails.fechaNacimiento).getTime()).getUTCFullYear() - 1970);
                            // @ts-ignore
                            this.pacienteService.pacienteActual = this.patientDetails;


                            if(!this.tutor){
                                this.catalogosService.GETRELtutores(patientView.idPaciente).subscribe(rt=>{
                                    console.log(rt);
                                    this.nombr=rt.nombreTutor;
                                    this.telef=rt.telefono;
                                    this.email=rt.email;
                                    this.paren=rt.parentesco;
                                });
                            }

                        }
                    })
            );
        } else {
            this.patientAge = new Date(Date.now() - new Date(this.patientDetails.fechaNacimiento).getTime()).getFullYear();
        }
    }
}
