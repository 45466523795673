import {Injectable, ModuleWithProviders, NgModule} from '@angular/core';
import {CustomHttp} from '../common/http/custom.http';
import {Observable} from 'rxjs/Observable';
import {PageImpl} from '../common/http/PageImpl';
import {RequestConfig} from '../shared/rest/rest.request';
import {Http, RequestMethod, Response} from '@angular/http';
import {PageRequest} from "../common/http/page.request";
import {NiomedicPageRequest} from "../common/http/niomedic.page.request";
import {ServicesSubject} from "../common/http/services.subject";
import {ConsultaView} from "../+consulta/shared/consulta.classes";
import {
    AparatosSistemasView, BeneficiarioGet, ClinicCourseView,
    ConsumoPacienteView, DatosClinicosView, EsquemaVacunacionView, ExploracionFisicaView, HeredoFamiliarView,
    PatientPageView, PatientView,
    PersonalNoPatologicoView,
    PersonalPatologicoView,
    TitularGet,
    TutorGet
} from "./paciente.classes";
import {UserAppView} from "../shared/classes/user.classes";
import { IndicacioesPA, IndicacionesGlu, IndicacionesPeso, InicacionesCovid, NivelesCovid, NivelesGlu, NivelesPA, NivelesPeso } from 'app/shared/classes/common.classes';
import { observable } from 'rxjs';

@Injectable()
export class PacienteService {
    private GET = new RequestConfig('Paciente-Get', 'application/json', true, RequestMethod.Get, '/pacientes/{idPaciente}');
    private GET_PAGE = new RequestConfig('Paciente-GetPage', 'application/json', true, RequestMethod.Get, '/pacientes/page/');
    private GET_PACE_CAN = new RequestConfig('Paciente-GetPageCan', 'application/json', true, RequestMethod.Get, '/pacientes/page/canalizado');
    private GET_PACE_ATENDIDO = new RequestConfig('Paciente-GetPageCan', 'application/json', true, RequestMethod.Get, '/pacientes/page/atendidos');

    private GET_PACIENTE_ID = new RequestConfig('Paciente-GetByIdUser', 'application/json', true, RequestMethod.Get, '/pacientes/obtenerPorIdUsuario/{idUsuario}');
    private POST_PACIENTE_USUARIO = new RequestConfig('Paciente-Post', 'application/json', true, RequestMethod.Post, '/paciente-usuario');
    private PUT_PACIENTE_USUARIO = new RequestConfig('Paciente-Post', 'application/json', true, RequestMethod.Put, '/paciente-usuario');
    private GET_PACIENTE_USUARIO = new RequestConfig('Paciente-User-Get', 'application/json', true, RequestMethod.Get, '/paciente-usuario/{idUsuario}');
    private PUT_PACIENTE = new RequestConfig('Paciente-Put', 'application/json', true, RequestMethod.Put, '/pacientes/{idPaciente}');

    private GET_CLINIC_COURSE = new RequestConfig("Paciente-GetClinicCourse", "application/json", true, RequestMethod.Get, '/cursoclinico/search');
    private GET_CLINIC_COURSE_CONSULTLIST = new RequestConfig("Paciente-GetClinicConsultList", "application/json", true, RequestMethod.Get, '/cursoclinico/lista-consultas');
    private PUT_DATOS_CLINICOSSTATUS = new RequestConfig('Paciente-PutClinicData', 'application/json', true, RequestMethod.Put, '/cursoclinico/actualizar-estatus?idPadecimiento={idpadicimiento}');

    //TITULARES

    private GET_TITULARESBYTELEFONO = new RequestConfig('PacienteTITULARES-GetByTEL', 'application/json', true, RequestMethod.Get, '/pacientes/getTitularByTelefono/{telefono}');
    private GET_TUTORES = new RequestConfig('PacienteTITULARES-GetByTutor', 'application/json', true, RequestMethod.Get, '/relacion-tutores/find-by-tutor/{idtutor}');
    private GET_Beneficiarios = new RequestConfig('PacienteTITULARES-GetByTutor', 'application/json', true, RequestMethod.Get, '/relacion-titular/beneficiarios/{idtutor}');

    private POST_RELACIONTUTORES = new RequestConfig('Paciente-PostClinicData', 'application/json', true, RequestMethod.Post, '/relacion-tutores');
    private POST_RELACIONTITULARES= new RequestConfig('Paciente-PostClinicData', 'application/json', true, RequestMethod.Post, '/relacion-titular');
   


    private GET_DATOS_CLINICOS = new RequestConfig('Paciente-GetClinicData', 'application/json', true, RequestMethod.Get, '/datos-clinicos/{idPaciente}');
    private POST_DATOS_CLINICOS = new RequestConfig('Paciente-PostClinicData', 'application/json', true, RequestMethod.Post, '/datos-clinicos');
    private PUT_DATOS_CLINICOS = new RequestConfig('Paciente-PutClinicData', 'application/json', true, RequestMethod.Put, '/datos-clinicos/{idPaciente}');

private GET_NOTIFICA = new RequestConfig('Paciente-GetNOTIFICAS', 'application/json', true, RequestMethod.Get, '/notificaciones-paciente/one?idUserApp={idUserApp}&idDevice={idDevice}&tipoNotificacion={tipoNoti}')
private DELETE_NOTIFICA = new RequestConfig('Paciente-GetNOTIFICAS', 'application/json', true, RequestMethod.Delete, '/notificaciones-paciente?idUserApp={idUserApp}&idDevice={idDevice}&tipoNotificacion={tipoNoti}')


//TELEMETRIA

private GET_INDGLU = new RequestConfig('Paciente-GetINDGLU', 'application/json', true, RequestMethod.Get, '/SaludIndGluc/{idPaciente}')
private POST_INDGLU = new RequestConfig('Paciente-PostINDGLU', 'application/json', true, RequestMethod.Post, '/SaludIndGluc');
private GET_INDPA = new RequestConfig('Paciente-GetINDPA', 'application/json', true, RequestMethod.Get, '/SaludIndPa/{idPaciente}')
private POST_INDPA = new RequestConfig('Paciente-PostINDPA', 'application/json', true, RequestMethod.Post, '/SaludIndPa');
private GET_INDPESO = new RequestConfig('Paciente-GetINDPESO', 'application/json', true, RequestMethod.Get, '/SaludIndNutri/{idPaciente}')
private POST_INDPESO = new RequestConfig('Paciente-PostINDPESO', 'application/json', true, RequestMethod.Post, '/SaludIndNutri');
private GET_INDCOVID = new RequestConfig('Paciente-GetINDCOVID', 'application/json', true, RequestMethod.Get, '/SaludIndCovid/{idPaciente}')
private POST_INDCOVID= new RequestConfig('Paciente-PostINDCOVID', 'application/json', true, RequestMethod.Post, '/SaludIndCovid');

private GET_NIVGLU = new RequestConfig('Paciente-GetNIVGLU', 'application/json', true, RequestMethod.Get, '/SaludNivGluc/search?pacidfk={idPaciente}')
private GET_NIVPA = new RequestConfig('Paciente-GetNIVPA', 'application/json', true, RequestMethod.Get, '/SaludNivPreart/search?pacidfk={idPaciente}')
private GET_NIVPESO = new RequestConfig('Paciente-GetNIVPESO', 'application/json', true, RequestMethod.Get, '/SaludNivPeso/search?pacidfk={idPaciente}')
private GET_NIVCOVID = new RequestConfig('Paciente-GetNIVCOVID', 'application/json', true, RequestMethod.Get, '/SaludNivCovid/search?pacidfk={idPaciente}')


private POST_NIVGLU = new RequestConfig('Paciente-POSTNIVGLU', 'application/json', true, RequestMethod.Post, '/SaludNivGluc/')
private POST_NIVPA = new RequestConfig('Paciente-POSTNIVPA', 'application/json', true, RequestMethod.Post, '/SaludNivPreart/')
private POST_NIVPESO = new RequestConfig('Paciente-POSTNIVPESO', 'application/json', true, RequestMethod.Post, '/SaludNivPeso/')
private POST_NIVCOVID = new RequestConfig('Paciente-POSTNIVCOVID', 'application/json', true, RequestMethod.Post, '/SaludNivCovid/')



private GET_NIVGLUFechas = new RequestConfig('Paciente-GetNIVGLU', 'application/json', true, RequestMethod.Get, '/SaludNivGluc/busqueda?pacidfk={idPaciente}&periodo={idPeriodo}&fechaInicio={FechaInicio}&fechaFin={FechaFin}')
private GET_NIVPAFechas = new RequestConfig('Paciente-GetNIVPA', 'application/json', true, RequestMethod.Get, '/SaludNivPreart/busqueda?pacidfk={idPaciente}&periodo={idPeriodo}&fechaInicio={FechaInicio}&fechaFin={FechaFin}')
private GET_NIVPESOFechas = new RequestConfig('Paciente-GetNIVPESO', 'application/json', true, RequestMethod.Get, '/SaludNivPeso/busqueda?pacidfk={idPaciente}&periodo={idPeriodo}&fechaInicio={FechaInicio}&fechaFin={FechaFin}')
private GET_NIVCOVIDFechas = new RequestConfig('Paciente-GetNIVCOVID', 'application/json', true, RequestMethod.Get, '/SaludNivCovid/busqueda?pacidfk={idPaciente}&periodo={idPeriodo}&fechaInicio={FechaInicio}&fechaFin={FechaFin}')


// FIN DE TELEMETRIA

//cuestionarios

private GET_QUIZFINALL = new RequestConfig('Paciente-GetNIVGLU', 'application/json', true, RequestMethod.Get, '/cat_preguntas/find')
private GET_QUIZFINDALL = new RequestConfig('Paciente-GetNIVGLU', 'application/json', true, RequestMethod.Get, '/cat_cuestionario/find')
private GET_QUIZFINID = new RequestConfig('Paciente-GetNIVGLU', 'application/json', true, RequestMethod.Get, '/cat_preguntas/find?idCuestionario={IdQuiz}')
private GET_ASKFINDID = new RequestConfig('Paciente-GetNIVGLU', 'application/json', true, RequestMethod.Get, '/cuestionario_respuesta/?idCuestionario={idCuestionario}&idPaciente={idPaciente}')


//fin cuestionarios

    private GET_HEREDO = new RequestConfig('Paciente-GetHeredo', 'application/json', true, RequestMethod.Get, '/heredo-familiar/{idPaciente')
    private POST_HEREDO = new RequestConfig('Paciente-PostHeredo', 'application/json', true, RequestMethod.Post, '/heredo-familiar');
    private PUT_HEREDO = new RequestConfig('Paciente-PutHeredo', 'application/json', true, RequestMethod.Put, '/heredo-familiar/{idPaciente')

    private GET_PATOLOGICO_IDPACIENTE = new RequestConfig('Paciente-GetPatologicoByIdPaciente', 'application/json', true, RequestMethod.Get, '/personal-patologico/{idPaciente}');
    private GET_PATOLOGICO = new RequestConfig('Paciente-GetPatologico', 'application/json', true, RequestMethod.Get, '/personal-patologico');
    private POST_PATOLOGICO = new RequestConfig('Paciente-PostPatologico', 'application/json', true, RequestMethod.Post, '/personal-patologico');
    private PUT_PATOLOGICO = new RequestConfig('Paciente-PutPatologico', 'application/json', true, RequestMethod.Put, '/personal-patologico/{idPaciente}');

    private GET_NOPATOLOGICO = new RequestConfig('Paciente-GetNoPat', 'application/json', true, RequestMethod.Get, '/personal-no-patologico/{idPaciente}');
    private POST_NOPATOLOGICO = new RequestConfig('Paciente-PostNoPat', 'application/json', true, RequestMethod.Post, '/personal-no-patologico');
    private PUT_NOPATOLOGICO = new RequestConfig('Paciente-PutNoPat', 'application/json', true, RequestMethod.Put, '/personal-no-patologico/{idPaciente}');

    private GET_CONSUMO_PAGE = new RequestConfig('Paciente-GetConsumoPage', 'application/json', true, RequestMethod.Get, '/consumo-paciente/page');
    private POST_CONSUMO = new RequestConfig('Paciente-PostConsumo', 'application/json', true, RequestMethod.Post, '/consumo-paciente');
    private PUT_CONSUMO = new RequestConfig('Paciente-PutConsumo', 'application/json', true, RequestMethod.Put, '/consumo-paciente/{idPaciente}');

    private GET_VACUNACION_PAGE = new RequestConfig('Paciente-GetVacunacionPage', 'application/json', true, RequestMethod.Get, '/esquema-vacunacion/page');
    private POST_VACUNACION = new RequestConfig('Paciente-PostVacunacion', 'application/json', true, RequestMethod.Post, '/esquema-vacunacion');
    private PUT_VACUNACION = new RequestConfig('Paciente-PutVacunacion', 'application/json', true, RequestMethod.Put, '/esquema-vacunacion/{idEsquemaVacunacion}');

    private GET_SISTEMAS = new RequestConfig('Paciente-GetSistemas', 'application/json', true, RequestMethod.Get, '/aparatos-sistemas/{idPaciente}');
    private POST_SISTEMAS = new RequestConfig('Paciente-PostSistemas', 'application/json', true, RequestMethod.Post, '/aparatos-sistemas');
    private PUT_SISTEMAS = new RequestConfig('Paciente-PutSistemas', 'application/json', true, RequestMethod.Put, '/aparatos-sistemas/{idPaciente}');

    private GET_EXPLORACION = new RequestConfig('Paciente-GetExplora', 'application/json', true, RequestMethod.Get, '/exploracion-fisica/{idPaciente}');
    private POST_EXPLORACION = new RequestConfig('Paciente-PostExplora', 'application/json', true, RequestMethod.Post, '/exploracion-fisica');
    private PUT_EXPLORACION = new RequestConfig('Paciente-PutExplora', 'application/json', true, RequestMethod.Put, '/exploracion-fisica/{idPaciente}');

    // Usos múltiples, cambiar parámetros en cada función request
    private reqGeneric = new RequestConfig('CR-PostPatologico', 'application/json', true, RequestMethod.Get, '/personal-patologico');

    private patientSelect: PatientPageView;
    private _pacienteActual: PatientView;

    private paciente: ServicesSubject<PatientView> = new ServicesSubject();
    private datosClinicos: ServicesSubject<DatosClinicosView> = new ServicesSubject();
    constructor(private http: CustomHttp,
                private _http: Http) {
    }

    /**
     * Pide al HTTP-REST que traiga los datos detalles del paciente
     * @see /pacientes/{idPaciente}
     * @param {string} idPaciente
     * @param {boolean} refresh (optional)
     * @returns {Observable<PatientView>}
     */
    getById(idPaciente: string, refresh?: boolean): Observable<PatientView> {
        if (this.paciente.needRefresh || refresh) {
            this.paciente.needRefresh = false;
            this.http.sendRequest(this.GET, null, idPaciente)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.paciente.needRefresh = !this.paciente.data;
                    return this.http.handleError(error);
                })
                .subscribe(patientView => {
                    this.paciente.subject.next(patientView);
                    this.paciente.data = patientView;
                });
        } else if (this.paciente.data && this.paciente.data.idPaciente != idPaciente) {
            delete this.paciente.data;
            this.paciente.subject.next(new PatientView());
            this.getById(idPaciente, true);
        }

        return this.paciente.subject.asObservable();
    }

    getPacienteUsuario (idUsuario: number): Observable<{pacienteVIew: PatientView, userAppView: UserAppView}> {
        return this.http.sendRequest(this.GET_PACIENTE_USUARIO,{image:true}, idUsuario)
            .map((res: Response) => res.json())
            .catch(error => Observable.throw(error));
    }

    /**
     * Obtiene detalles del pacieente por IDUsuario
     * @param idUsuario
     */
    getByIdUser(idUsuario: number, refresh?: boolean): Observable<PatientView> {
        if (this.paciente.needRefresh || refresh) {
            this.paciente.needRefresh = false;
            this.http.sendRequest(this.GET_PACIENTE_ID, null, idUsuario)
                .map((res: Response) => res.json())
                .catch(error => {
                    this.paciente.needRefresh = !this.paciente.data;
                    return this.http.handleError(error);
                })
                .subscribe(patientView => {
                    this.paciente.subject.next(patientView);
                    this.paciente.data = patientView;
                });
        } else if (this.paciente.data) {
            if (this.paciente.data.idUsuario != idUsuario) {
                delete this.paciente.data;
                this.paciente.subject.next(new PatientView());
                this.getByIdUser(idUsuario, true);
            }
        }

        return this.paciente.subject.asObservable();
    }

    getPage(options?: PageRequest): Observable<PageImpl<PatientPageView>> {
        let patientSearch: PageRequest = options ? options : new PageRequest();
        // fix for MS Pacientes 20190403
        options.datosBusqueda = options.name;
        return this.http.sendRequest(this.GET_PAGE, patientSearch)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }
    getPageCanalizado(options?: PageRequest): Observable<PageImpl<PatientPageView>> {
        let patientSearch: PageRequest = options ? options : new PageRequest();
        // fix for MS Pacientes 20190403
        options.datosBusqueda = options.name;
        return this.http.sendRequest(this.GET_PACE_CAN, patientSearch)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }
    getPageAtendidos(options?: PageRequest): Observable<PageImpl<PatientPageView>> {
        let patientSearch: PageRequest = options ? options : new PageRequest();
        // fix for MS Pacientes 20190403
        options.datosBusqueda = options.name;
        return this.http.sendRequest(this.GET_PACE_ATENDIDO, patientSearch)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }


    /**
     * Alta de nuevo paciente
     * @param paciente
     */
    savePatient(pacienteUsuario: {pacienteVIew: PatientView, userAppView: UserAppView,selectedGroup : number}): Observable<PatientView> {
        return this.http.sendRequest(this.POST_PACIENTE_USUARIO, pacienteUsuario)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Actualizar un paciente
     * @param paciente
     */
    updatePatient(pacienteUsuario: {pacienteVIew: PatientView, userAppView: UserAppView, selectedGroup : number}): Observable<PatientView> {
        return this.http.sendRequest(this.PUT_PACIENTE_USUARIO, pacienteUsuario)
            .map((res: Response) => {
                this.paciente = new ServicesSubject();
                return res.json();
            })
            .catch(error => this.http.handleError(error));
    }

    setPatient(patient: {pacienteVIew: PatientView, userAppView: UserAppView, selectedGroup : number, code: string}, isEditing: boolean = false): Observable<PatientView> {
        if (isEditing) {
            return this.updatePatient(patient);
        } else {
            return this.savePatient(patient);
        }
    }

    get pacienteActual(): PatientView {
        return this._pacienteActual;
    }

    set pacienteActual(value: PatientView) {
        this._pacienteActual = value;
    }


    get patient(): PatientPageView {
        return this.patientSelect;
    }

    set patient(patient: PatientPageView) {
        this.patientSelect = patient;
    }

    /**
     * Agregar datos clínicos
     * @param datosClinicos DatosClinicosView
     */
    saveClinicData(datosClinicos: DatosClinicosView): Observable<Response> {
        return this.http.sendRequest(this.POST_DATOS_CLINICOS, datosClinicos)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Actualizar datos clínicos
     * @param idPaciente
     * @param datosClinicos DatosClinicosView
     */
    updateClinicData(idPaciente: string, datosClinicos: DatosClinicosView): Observable<Response> {
        return this.http.sendRequest(this.PUT_DATOS_CLINICOS, datosClinicos, idPaciente || datosClinicos.idPaciente)
            .map((res: Response) => {
                this.datosClinicos = new ServicesSubject();
                return res;
            })
            .catch(error => this.http.handleError(error));
    }

    /**
     * ### Agrega o actualiza los datos clínicos de un paciente
     * ### si nuevo:
     * > HTTP-METHOD: POST
     * > URL: /datos-clinicos/
     * > Body: DatosClinicosView
     *
     *  ### si actualizar:
     * > HTTP-METHOD: PUT
     * > URL: /datos-clinicos/{idPaciente}
     * > URL Param:  idPaciente: string el Id del paciente
     * > Body: DatosClinicosView
     * @see DatosClinicosView
     * @param datosClinicos Los datos a guardar
     * @param idPaciente El string con el id del paciente
     */
    setClinicData(datosClinicos: DatosClinicosView, idPaciente: string): Observable<Response> {
        let isEditing: boolean = !!datosClinicos.idDatosClinicos;
        if (isEditing) {
            return this.updateClinicData(idPaciente, datosClinicos);
        } else {
            return this.saveClinicData(datosClinicos);
        }
    }

    /**
     * Obtener datos clinicos de un paciente por id_paciente
     * @param {string} idPaciente
     * @param refresh
     * @returns {Observable<DatosClinicosView>}
     */
    getClinicDataByIdPaciente(idPaciente: string, refresh?: boolean): Observable<DatosClinicosView> {
        if (this.datosClinicos.needRefresh || refresh) {
            this.datosClinicos.needRefresh = false;
            this.http.sendRequest(this.GET_DATOS_CLINICOS, null, idPaciente)
                .map((res: Response) => {
                    try {
                        return res.json();
                    } catch (e) {
                        return {};
                    }
                })
                .catch((error) => {
                    this.datosClinicos.needRefresh = !this.datosClinicos.data;
                    return Observable.throw(error);
                })
                .subscribe((datosClinicosView) => {
                    this.datosClinicos.subject.next(datosClinicosView);
                    this.datosClinicos.data = datosClinicosView;
                });
        } else if (this.datosClinicos.data && this.datosClinicos.data.idPaciente != idPaciente) {
            delete this.datosClinicos.data;
            this.datosClinicos.subject.next(new DatosClinicosView());
            this.getClinicDataByIdPaciente(idPaciente, true);
        }

        return this.datosClinicos.subject.asObservable();
    }






    ///// ==================================  \\\\\\\\
    //     REST: HEREDO FAMILIARES                \\\\
    //        TODO: DELETE                         \\\\
    ///// ==================================   \\\\\\\\
    /**
     * 1. Obtener Heredo Familiar
     * @param idPaciente string
     * @see HeredoFamiliarView
     */


     getNotipac(idUserApp: any,idDevice:any, tipoNoti:any): Observable<any> {
     return this.http.sendRequest(this.GET_NOTIFICA, null, idUserApp,idDevice,tipoNoti)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }

    dellNotipac(idUserApp: any,idDevice:string,tipoNoti:any): Observable<any> {
        return this.http.sendRequest(this.DELETE_NOTIFICA, null, idUserApp,idDevice,tipoNoti)
               .map((res: Response) => res.ok)
               //.catch(error => this.http.handleError(error));
       }

       postNotiPacientes(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'NOTIFICACIONES_CRON';
        this.reqGeneric.uri = '/notificaciones-paciente';
        return this.http.sendRequest(this.reqGeneric, value)
        .map((res: Response) => res.ok)
        .catch(error => this.http.handleError(error));
    }

//REST TELEMETRIA

    getINDGLU(idPaciente: string): Observable<IndicacionesGlu> {
        return this.http.sendRequest(this.GET_INDGLU, null, idPaciente)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }
    getINDPA(idPaciente: string): Observable<IndicacioesPA> {
        return this.http.sendRequest(this.GET_INDPA, null, idPaciente)
            .map((res: Response) => res.json())
          //  .catch(error => this.http.handleError(error));
    }
    getINDPESO(idPaciente: string): Observable<IndicacionesPeso> {
        return this.http.sendRequest(this.GET_INDPESO, null, idPaciente)
            .map((res: Response) => res.json())
    //            .catch(error => this.http.handleError(error));
    }
    getINDCOVID(idPaciente: string): Observable<InicacionesCovid> {
        return this.http.sendRequest(this.GET_INDCOVID, null, idPaciente)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }



    getNIVGLU(idPaciente: string): Observable<any> {
        return this.http.sendRequest(this.GET_NIVGLU, null, idPaciente)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }
    getNIVPA(idPaciente: string): Observable<any> {
        return this.http.sendRequest(this.GET_NIVPA, null, idPaciente)
            .map((res: Response) => res.json())
          //  .catch(error => this.http.handleError(error));
    }
    getNIVPESO(idPaciente: string): Observable<any> {
        return this.http.sendRequest(this.GET_NIVPESO, null, idPaciente)
            .map((res: Response) => res.json())
    //            .catch(error => this.http.handleError(error));
    }
    getNIVCOVID(idPaciente: string): Observable<any> {
        return this.http.sendRequest(this.GET_NIVCOVID, null, idPaciente)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }



    setNIVGLU(niv: NivelesGlu ): Observable<any> {
        return this.http.sendRequest(this.POST_NIVGLU, niv)
        .map((res: Response) => res.ok)
        .catch(error => this.http.handleError(error));
    }
    setNIVPA(niv: NivelesPA): Observable<any> {
        return this.http.sendRequest(this.POST_NIVPA, niv)
        .map((res: Response) => res.ok)
        .catch(error => this.http.handleError(error));
    }
    setNIVPESO(niv: NivelesPeso): Observable<any> {
        return this.http.sendRequest(this.POST_NIVPESO,niv)        
        .map((res: Response) => res.ok)
        .catch(error => this.http.handleError(error));
    }
    setNIVCOVID(niv: NivelesCovid): Observable<any> {
        return this.http.sendRequest(this.POST_NIVCOVID,niv)
        .map((res: Response) => res.ok)
        .catch(error => this.http.handleError(error));

    }



    getNIVPESOFechas(idPaciente: string,idPeriodo: string,FechaInicio: string,FechaFin: string): Observable<any> {
        return this.http.sendRequest(this.GET_NIVPESOFechas, null, idPaciente,idPeriodo,FechaInicio,FechaFin)
            .map((res: Response) => res.json())
    //            .catch(error => this.http.handleError(error));
    }
    
    getNIVGLUFechas(idPaciente: string,idPeriodo: string,FechaInicio: string,FechaFin: string): Observable<any> {
        return this.http.sendRequest(this.GET_NIVGLUFechas, null, idPaciente,idPeriodo,FechaInicio,FechaFin)
            .map((res: Response) => res.json())
    //            .catch(error => this.http.handleError(error));
    }
    

    getNIVPAFechas(idPaciente: string,idPeriodo: string,FechaInicio: string,FechaFin: string): Observable<any> {
        return this.http.sendRequest(this.GET_NIVPAFechas, null, idPaciente,idPeriodo,FechaInicio,FechaFin)
            .map((res: Response) => res.json())
    //            .catch(error => this.http.handleError(error));
    }
    

    getNIVCOVIDFechas(idPaciente: string,idPeriodo: string,FechaInicio: string,FechaFin: string): Observable<any> {
        return this.http.sendRequest(this.GET_NIVCOVIDFechas, null, idPaciente,idPeriodo,FechaInicio,FechaFin)
            .map((res: Response) => res.json())
    //            .catch(error => this.http.handleError(error));
    }
    

//FIN REST TELEMETRIA 


    getHeredoFamiliares(idPaciente: string): Observable<HeredoFamiliarView> {
        return this.http.sendRequest(this.GET_HEREDO, null, idPaciente)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * 2. Alta de historia clínica
     * @param heredofamiliares HeredoFamiliarView
     */
    saveHeredoFamiliares(heredofamiliares: HeredoFamiliarView): Observable<Response> {
        return this.http.sendRequest(this.POST_HEREDO, heredofamiliares)
            .catch(error => this.http.handleError(error));
    }

    /**
     * 3. Actualizar Heredo Familiar
     * @param value HeredoFamiliarView
     * @param idPaciente string
     */
    updateHeredoFamiliares(value: HeredoFamiliarView, idPaciente: string): Observable<Response> {
        return this.http.sendRequest(this.PUT_HEREDO, value, idPaciente || value.idPaciente)
            .catch(error => this.http.handleError(error));
    }

    ///// ==================================  \\\\\\\\
    //     REST: PERSONAL PATOLÓGICO              \\\\
    //        TODO: DELETE                        \\\\
    ///// ==================================   \\\\\\\\
    /**
     * 1. Obtener los datos Patológicos del paciente
     * @param idPaciente string
     */
    getPersonalPatologico(idPaciente: string): Observable<PersonalPatologicoView> {
        return this.http.sendRequest(this.GET_PATOLOGICO_IDPACIENTE, null, idPaciente)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * 2. Crear Personal Patológico
     * @param value PersonalPatologicoView
     */
    savePersonalPatologico(value: PersonalPatologicoView): Observable<Response> {
        return this.http.sendRequest(this.POST_PATOLOGICO, value)
            .catch(error => this.http.handleError(error));
    }

    /**
     * 3. Actualizar Personal Patológico
     * @param value PersonalPatologicoView
     */
    updatePersonalPatologico(value: PersonalPatologicoView): Observable<Response> {
        return this.http.sendRequest(this.PUT_PATOLOGICO, value, value.idPaciente)
            .catch(error => this.http.handleError(error));
    }

    ///// ==================================  \\\\\\\\
    //     REST: CONSUMO PACIENTE                 \\\\
    //        TODO: DELETE                        \\\\
    ///// ==================================   \\\\\\\\
    /**
     * 1. Obtener Consumo Paciente
     * @param idPaciente string
     */
    getConsumoPaciente(idPaciente: string, options?: PageRequest): Observable<PageImpl<ConsumoPacienteView>> {
        if (!options) {
            options = new PageRequest();
            options.orderColumn = 'idPaciente';
            options.orderType = 'desc';
        }
        options.active = true;
        options.name = idPaciente;

        return this.http.sendRequest(this.GET_CONSUMO_PAGE, options)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * 2. Alta consumo Paciente
     * @param comosumoPaciente ConsumoPacienteView
     */
    saveConsumoPaciente(comosumoPaciente: ConsumoPacienteView): Observable<Response> {
        return this.http.sendRequest(this.POST_CONSUMO, comosumoPaciente)
            .catch(error => this.http.handleError(error));
    }

    /**
     * 3. Actualizar consumo Paciente
     * @param value ConsumoPacienteView
     * @param idPaciente
     */
    updateConsumoPaciente(value: ConsumoPacienteView, idPaciente: String): Observable<Response> {
        return this.http.sendRequest(this.PUT_CONSUMO, value, idPaciente || value.idPaciente)
            .catch(error => this.http.handleError(error));
    }

    ///// ==================================  \\\\\\\\
    //     REST: ESQUEMA VACUNACIÓN               \\\\
    //        TODO: DELETE                        \\\\
    ///// ==================================   \\\\\\\\
    /**
     * 1. Obtener Esquema de vacunación
     * @param idPaciente string
     */
    getEsquemaVacunacion(idPaciente: string, options?: PageRequest): Observable<PageImpl<EsquemaVacunacionView>> {
        if (!options) {
            options = new PageRequest();
            options.orderColumn = 'dosis';
            options.orderType = 'desc';
        }
        options.name = idPaciente;

        return this.http.sendRequest(this.GET_VACUNACION_PAGE, options)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * 2. Alta Esquema Vacunación
     * @param esquemaVacunacion EsquemaVacunacionView
     */
    saveEsquemaVacunacion(esquemaVacunacion: EsquemaVacunacionView): Observable<Response> {
        return this.http.sendRequest(this.POST_VACUNACION, esquemaVacunacion)
            .catch(error => this.http.handleError(error));
    }

    /**
     * 3. Actualizar Esquema de vacunación
     * @param value EsquemaVacunacionView
     * @param idEsquemaVacunacion
     */
    updateEsquemaVacunacion(value: EsquemaVacunacionView, idEsquemaVacunacion: string): Observable<Response> {
        return this.http.sendRequest(this.PUT_VACUNACION, value, idEsquemaVacunacion || value.idEsquemaVacunacion)
            .catch(error => this.http.handleError(error));
    }

    ///// ==================================  \\\\\\\\
    //     REST: PERSONAL - NO - PATOLÓGICO       \\\\
    //        TODO: DELETE                        \\\\
    ///// ==================================   \\\\\\\\
    /**
     * 1. Obtener datos del paciente NO PATOLÓGICO : return PersonalNoPatologicoView
     * @param idPaciente
     * @return Observable<PersonalNoPatologicoView>
     */
    getPersonalNoPatologico(idPaciente: string): Observable<PersonalNoPatologicoView> {
        return this.http.sendRequest(this.GET_NOPATOLOGICO, null, idPaciente)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * 2. Crear NO patologico
     * @param personalNoPatlogico PersonalNoPatologicoView
     */
    savePersonalNoPatologico(personalNoPatlogico: PersonalNoPatologicoView): Observable<Response> {
        return this.http.sendRequest(this.POST_NOPATOLOGICO, personalNoPatlogico)
            .catch(error => this.http.handleError(error));
    }

    /**
     * 3. Actualizar  Personal No Patológico
     * @param value PersonalNoPatologicoView
     * @param idPaciente
     */
    updatePersonalNoPatologico(value: PersonalNoPatologicoView, idPaciente: string): Observable<Response> {
        return this.http.sendRequest(this.PUT_NOPATOLOGICO, value, idPaciente || value.idPaciente)
            .catch(error => this.http.handleError(error));
    }

    ///// ==================================  \\\\\\\\
    //     REST: SISTEMAS                          \\\\
    //        TODO: DELETE                        \\\\
    ///// ==================================   \\\\\\\\
    /**
     * Pide al HTTP-REST que traiga los datos de aparatos y sistemas del paciente
     * @see /aparatos-sistemas/{idPaciente}
     * @param idPaciente string
     */
    getAparatosSistemas(idPaciente: string): Observable<AparatosSistemasView> {
        return this.http.sendRequest(this.GET_SISTEMAS, null, idPaciente)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * 2. Crear Sistemas
     * @param {AparatosSistemasView} aparatosSistemasView
     * @return {Observable<Response>}
     */
    saveSistemas(aparatosSistemasView: AparatosSistemasView): Observable<Response> {
        return this.http.sendRequest(this.POST_SISTEMAS, aparatosSistemasView)
            .catch(error => this.http.handleError(error));
    }

    /**
     * 3. Actuarlizar Sistemas
     * @param {PatientView} paciente
     * @param {AparatosSistemasView} datosSistema
     * @return {Observable<Response | any>}
     */
    updateSistemas(idPaciente: string, datosSistema: AparatosSistemasView): Observable<Response> {
        return this.http.sendRequest(this.PUT_SISTEMAS, datosSistema, idPaciente)
            .catch(error => this.http.handleError(error));
    }

    /** -------------------------------------------
     EXPLORACION FISICA
     */
    /**
     * Obtener la exploración por paciente
     * @param {string} idPaciente
     * @return {Observable<ExploracionFisicaView>}
     */
    getExploracionFisica(idPaciente: string): Observable<ExploracionFisicaView> {
        return this.http.sendRequest(this.GET_EXPLORACION, null, idPaciente)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Agregar exploración física del paciente
     *
     */
    saveExploracionFisica(datosExplora: ExploracionFisicaView): Observable<Response> {
        return this.http.sendRequest(this.POST_EXPLORACION, datosExplora)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Actualizar la Exploración Fisica del paciente
     * @param {ExploracionFisicaView} value
     * @param {string} idPaciente
     * @return {Observable<Response>}
     */
    updateExploracionFisica(value: ExploracionFisicaView, idPaciente: string): Observable<Response> {
        return this.http.sendRequest(this.PUT_EXPLORACION, value, idPaciente)
            .catch(error => this.http.handleError(error));
    }

    getClinicCourse(options: NiomedicPageRequest): Observable<PageImpl<ClinicCourseView>> {
        if (!!options.idPaciente) {
            options.orderColumn = options.orderColumn || "fechaCreacion";
            options.orderType = options.orderType || "desc";
            return this.http.sendRequest(this.GET_CLINIC_COURSE, options)
                .map((res: Response) => res.json())
                .catch(error => this.http.handleError(error));
        } else {
            console.error("error on PacienteService.getClinicCourse(): Property idPaciente is required to get Clinic Course list");
            return new Observable(subscriber => subscriber.error("idPaciente is required"));
        }
    }

    getClinicCourseConsultList(options: NiomedicPageRequest): Observable<PageImpl<ConsultaView>> {
        if (!!options.idPaciente && !!options.idPadecimiento) {
            return this.http.sendRequest(this.GET_CLINIC_COURSE_CONSULTLIST, options)
                .map((res: Response) => res.json())
                .catch(error => this.http.handleError(error));
        } else {
            console.error('error on PacienteService.getClinicCourseConsultList(): Property idPaciente and idPadecimiento are required to get Consults list');
            return new Observable(subscriber => subscriber.error('idPaciente and idPadecimiento are required'));
        }
    }


    updateClinicStatus(idpadicimiento: any) {
        this.reqGeneric.method = RequestMethod.Put;
        this.reqGeneric.code = 'PUT STATUS';
        this.reqGeneric.uri = '/cursoclinico/actualizar-estatus?idPadecimiento='+idpadicimiento;
        return this.http.sendRequest(this.reqGeneric).map((res: Response) => res.ok)
            .catch(error => this.http.handleError(error));
    }

    // updateClinicStatus(idpadicimiento: any){
    //     return this.http.sendRequest(this.PUT_DATOS_CLINICOSSTATUS, idpadicimiento)
    //     .map((res: Response) => res.ok).catch(error => this.http.handleError(error));
    // }

    getCatVacuna() {
        return this._http.get('/assets/api/catVacunas.json').map((res) => res.json());
    }

    getHistoriaCG(idPaciente: string) {
        this.reqGeneric.method = RequestMethod.Get;
        this.reqGeneric.code = 'getHistoriaCG';
        this.reqGeneric.uri = '/historia-clinica/{idPaciente}';
        return this.http.sendRequest(this.reqGeneric, {}, idPaciente)
            .map(res => {
                try {
                    return res.json();
                } catch (e) {
                    console.warn(e);
                    console.log(e);
                    
                    return null;
                }
            })
            .catch(error => Observable.throw(error));
    }

    postHistoriaCG(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'postHistoriaCG';
        this.reqGeneric.uri = '/historia-clinica';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }

    //cuestionarios.

        //getall
    getQuizAll(): Observable<any> {
        return this.http.sendRequest(this.GET_QUIZFINALL, null)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }

    getCatQuizAll(): Observable<any> {
        return this.http.sendRequest(this.GET_QUIZFINDALL, null)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }

 
    getCatASKBYID(idCuestionario:number,idPaciente:string): Observable<any> {
        return this.http.sendRequest(this.GET_ASKFINDID, null,idCuestionario,idPaciente)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }
    
       // getllquiz
    getQuizbyId(IdQuiz: any): Observable<any> {
        return this.http.sendRequest(this.GET_QUIZFINID, null, IdQuiz)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }

    postCuestionarios(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'CUESTIONARIOPOST';
        this.reqGeneric.uri = '/cat_preguntas';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }

    postRestAnswer(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'CUESTIONARIOPOST';
        this.reqGeneric.uri = '/cuestionario_respuesta';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }

//put
    putCuestionarios(value: any,idPregunta:string) {
        this.reqGeneric.method = RequestMethod.Put;
        this.reqGeneric.code = 'CUESTIONARIOPUT';
        this.reqGeneric.uri = '/cat_preguntas/'+idPregunta;
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }

    //fin cuestionarios

    //TUTORES-titulares

    getTITByTEL(telefono: string): Observable<TitularGet> {
        return this.http.sendRequest(this.GET_TITULARESBYTELEFONO, null, telefono)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }
    getTitTut(idtutor: string): Observable<TutorGet> {
        return this.http.sendRequest(this.GET_TUTORES, null, idtutor)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }
    getTitBene(idtutor: string): Observable<Array<BeneficiarioGet>> {
        return this.http.sendRequest(this.GET_Beneficiarios, null, idtutor)
            .map((res: Response) => res.json())
            //.catch(error => this.http.handleError(error));
    }


   
    postRelaciontutores(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'Paciente-RELACIONTUTORES';
        this.reqGeneric.uri = '/relacion-tutores';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    postRelaciontitulares(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'Paciente-RELACIONTITULARES';
        this.reqGeneric.uri = '/relacion-titular';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }

 

    postTutor(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'TUTOR';
        this.reqGeneric.uri = '/Tutores';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    postTutorRelaciones(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'TUTOR RELACIONES';
        this.reqGeneric.uri = '/relacion-tutores';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }

    //POST TELEMETRIA
    postINDGLU(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'GLUCOSAINDICACIONES';
        this.reqGeneric.uri = '/SaludIndGluc';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    postINDPA(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'PAINDICACIONES';
        this.reqGeneric.uri = '/SaludIndPa';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    postINDPESO(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'PESOINDICACIONES';
        this.reqGeneric.uri = '/SaludIndNutri';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    postINDCOVID(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'COVIDINDICACIONES';
        this.reqGeneric.uri = '/SaludIndCovid';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
//put
    puttINDGLU(value: any,idpac:string) {
        this.reqGeneric.method = RequestMethod.Put;
        this.reqGeneric.code = 'GLUCOSAINDICACIONES';
        this.reqGeneric.uri = '/SaludIndGluc/'+idpac;
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    putINDPA(value: any,idpac:string) {
        this.reqGeneric.method = RequestMethod.Put;
        this.reqGeneric.code = 'PAINDICACIONES';
        this.reqGeneric.uri = '/SaludIndPa/'+idpac;
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    putINDPESO(value: any,idpac:string) {
        this.reqGeneric.method = RequestMethod.Put;
        this.reqGeneric.code = 'PESOINDICACIONES';
        this.reqGeneric.uri = '/SaludIndNutri/'+idpac;
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    putINDCOVID(value: any,idpac:string) {
        this.reqGeneric.method = RequestMethod.Put;
        this.reqGeneric.code = 'COVIDINDICACIONES';
        this.reqGeneric.uri = '/SaludIndCovid/'+idpac;
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }

    postNIVGLU(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'GLUCOSANIVELES';
        this.reqGeneric.uri = '/SaludNivGluc';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    postNIVPA(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'PANIVELES';
        this.reqGeneric.uri = '/SaludNivPreart';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    postNIVPESO(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'PESONIVELES';
        this.reqGeneric.uri = '/SaludNivPeso';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }
    postNIVCOVID(value: any) {
        this.reqGeneric.method = RequestMethod.Post;
        this.reqGeneric.code = 'COVIDNIVELES';
        this.reqGeneric.uri = '/SaludNivCovid';
        return this.http.sendRequest(this.reqGeneric, value)
            .catch(error => this.http.handleError(error));
    }

    //FIN POST TELEMETRIA
    putHistoriaCG(idPaciente: string, value: any) {
        this.reqGeneric.method = RequestMethod.Put;
        this.reqGeneric.code = 'putHistoriaCG';
        this.reqGeneric.uri = '/historia-clinica/{idPaciente}';
        return this.http.sendRequest(this.reqGeneric, value, idPaciente)
            .catch(error => this.http.handleError(error));
    }
}

@NgModule({
})
export class PacienteServicesModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: PacienteServicesModule,
            providers: [PacienteService]
        }
    }
}
